import {TreeView} from '@mui/x-tree-view/TreeView'
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {TreeItem} from '@mui/x-tree-view'
import React from "react";


export default function FolderTree({buckets, selectBucket, selectFolder}) {

    return (
        <div className="col-lg-3 col-md-4 col-sm-12 p-0 ms-4 mt-5">
            <TreeView
                aria-label="S3 Buckets"
                defaultExpanded={['3']}
                defaultCollapseIcon={<ArrowDropDownIcon/>}
                defaultExpandIcon={<ArrowRightIcon/>}
                defaultEndIcon={<div style={{width: 50}}/>}
                sx={{height: '100%', flexGrow: 1, maxWidth: 500, overflowY: 'auto'}}
            >
                {buckets.map((bucket) => (
                    <TreeItem key={bucket.name} nodeId={bucket.name} label={bucket.groupName}
                              onClick={() => selectBucket(bucket)}>
                        {bucket.children && Object.entries(bucket.children).length > 0
                            && drawChildren(bucket, bucket.children)}
                    </TreeItem>
                ))}
            </TreeView>
        </div>
    );

    /**
     * Bucket Tree에 폴더 목록을 추가 한다.
     *
     * @param bucket buket 객체
     * @param children 하위 객체 map
     * @returns {*[]} TreeItem
     */
    function drawChildren(bucket, children) {
        return Object.values(children).filter((file) => file.type === "dir").map((dir) => {
                //console.debug("dir : ", dir)
                return (
                    <TreeItem key={dir.key} nodeId={dir.key} label={dir.name}
                              onClick={() => selectFolder(bucket, dir)}>
                        {dir.children && Object.values(dir.children).length > 0 && drawChildren(bucket, dir.children)}
                    </TreeItem>)
            }
        )
    }

}