import React, {createContext, useContext, useEffect, useState} from 'react';
import {callApi, UserContext} from './App';
import Login from './Login';
import S3 from "./storage/S3";

export const MainContext = createContext({
    // groups: [],
    buckets: [],
    message: {},
    setBuckets: () => {}
});


export default function Main() {
    const [path, setPath] = useState(window.location.pathname)
    const user = useContext(UserContext);
    const [buckets, setBuckets] = useState(null);

    useEffect(() => {
        if( user.email === undefined || user.email === '' ) return
        callApi("/s3/buckets", setBuckets)
    },[user.email])

    const context = {buckets:buckets, setBuckets: setBuckets, setPath: setPath};
    //const isStorageHost = window.location.host.startsWith("storage.bwg.co.kr")

    console.debug("[Main] pathname : ", path, user.email )

    return (<MainContext.Provider value={context}>
        {user.email && <S3/>}
        {user.email === "" && <Login />}
    </MainContext.Provider>);


}