import React, {useContext, useEffect, useState} from "react";
import {callApi, genPathUrl} from "../App";
import {Row} from "react-bootstrap";
import FileLists from "./FileLists";
import Menu from "./Menu";
import FolderTree from "./FolderTree";
import {MainContext} from "../Main";

export default function S3() {
    const context = useContext(MainContext);
    const buckets = context.buckets
    const [selection, setSelection] = useState({}) // 선택된 bucket, file목록 , 경로 
    const [loadingState, setLoadingState] = useState(false)
    const [query] = useState(new URLSearchParams(window.location.search));
    const [requestPath, setRequestPath] = useState()

    useEffect(() => {
        if( buckets === null || requestPath > "" ) return; // 한번만 수행 한다. 
        const rpath = query.get("path")
        if( rpath == null ) return 
        setRequestPath(rpath)
        console.debug("[Main.useEffect] request path : ", rpath)

        // 첫번째와 마지막 '/'는 삭제하고, '/'로 구분하면 
        const dir = rpath.replace(/^\//gi,"").replace(/\/$/gi,"").split("/")
        dir.push("")
        console.debug("[Main.useEffect] dir : ", dir.length, dir[0])

        // 이름으로 `Bucket` 객체를 찾는다.
        const bucket = buckets.filter((b) => b.name === dir[0])[0]
        console.debug("[Main.useEffect] bucket : ", bucket)
        if(bucket) reload( bucket, dir )
        
    },[query, requestPath, buckets])


    function refreshFiles(bucket, path, result, isChageList) {
        // result는 현재 path의 객체 목록을 가져온다. => bucket.children에서 현재 경로를 찾아야 한다.
        buckets.filter((b) => b.name === bucket.name).map((b) => {
            // 경로가 bucket 이름이면 결과는 bucket의 children이다.
            if (path === undefined || path.filter(d => d !== "").length === 1) {
                b.children = result
                setSelection({bucket: bucket, files: bucket.children, dir: [bucket.name]})
            } else {// path=['bucket-name','okb',''] key='okb/' , path=['bucket-name','okb','UI',''] key='okb/UI/'
                let files = b // ['okb', 'test']
                let i = 1
                let key = ""
                while (true) {
                    key += (path[i] + "/")
                    if (files.children === undefined) { // 경로를 직접 입력한 경우 중간 경로가 없을 수 있다. 임의로 만든다.
                        files.children = {}
                        files.children[path[i]] = {name: path[i], key: key, type: "dir", size: 0}
                    }
                    files = files.children[path[i]]; // 'okb'
                    i++
                    if (path[i] === "" || path[i] === undefined) {
                        files.children = result
                        break;
                    }
                }
                if(isChageList) setSelection({bucket: bucket, files: files.children, dir: path})
            }
        })
    }

    /**
     * `Bucket`의 폴더 단위 파일 목록 조회
     *  - folder tree에서 선택
     *  - reload button click
     *  - path에 경로가 지정된 경우
     *
     * @param data (Optional) 목록을 조회 할 bucket 객체
     * @param path 현재 위치 목록. ex)  [bucket이름, 폴더이름1, 하위폴더이름2,...]
     * @param isChageList (Optional) 파일 목록을 변경할 것인지 여부. 기본값은 true.
     */
    function reload(data, path, isChageList = true) { // `FileLists`에서 호출 할 때는 data 없음.
        console.debug(`[S3.reload] reload : path - "${path}", selection.dir : ${selection.dir}, data - `, data)
        // 지정된 객체가 없으면, 기존에 선택한 객체를 사용 한다.
        const bucket = data === undefined || data == null || data.name === undefined ? selection.bucket : data

        // path 값으로 folder key 생성 
        let key = path=== undefined || path === "" ? selection.dir.slice(1).join('/') : path.slice(1).join('/')
        if( key > "" && key.endsWith("/") === false ) key += "/"
        console.debug("[S3.reload] key : ", key)

        if( path === undefined ) path = selection.dir 

        setLoadingState(true) // 화면 클릭방지
        const url = `/s3/bucket/${bucket.name}/objects?profile=${bucket.profile}&folderKey=${encodeURIComponent(key)}`;
        callApi(url, (result) => {
            setLoadingState(false) // 화면 클릭방지 해제
            refreshFiles(bucket, path, result, isChageList);
            context.setBuckets([...buckets])

        },"has error", genPathUrl(bucket.name, key))
    }


    /**
     * 선택한 `Bucket`의 파일 목록(children)이 없으면 조회 해서 selection에 지정한다.
     *
     * @param bucket `Tree`에서 선택한 Bucket 객체
     */
    function selectBucket(bucket) {
        console.debug("[S3.selectBucket] bucket: ", bucket)
        // 파일 목록이 이미 있으면 `selection`에 지정
        if (bucket.children && Object.entries(bucket.children).length > 0) {
            // location에 path 파라미터로 접근한 경우 `date` 필드가 없다. ==> reload를 호출해서 목록을 조회 한다. 
            if( Object.values(bucket.children)[0].date === undefined ) reload( bucket, [bucket.name])
            else setSelection({bucket: bucket, files: bucket.children, dir: [bucket.name]})
            return
        }
        // 파일 목록이 없으면 데이터 로딩
        reload(bucket, [bucket.name]);
    }

    /**
     * `Tree`에서 선택한 폴더 정보를 `selection`에 지정 한다.
     *
     * @param bucket 상위 경로 이름 (전체)
     * @param folder `Tree`에서 선택한 폴더 객체
     */
    function selectFolder(bucket, folder) {
        // 선택한 객체의 `Bucket` 이름을 찾는다.
        console.debug("[S3.selectFolder] folder : ", folder)
        console.debug("[S3.selectFolder] bucket name : ", bucket.name)

        // 이름으로 `Bucket` 객체를 찾는다.
        //const bucket = buckets.filter((b) => b.name === bucketName)[0]

        if( Object.entries(folder.children).length === 0 || folder.date === undefined ) 
            reload(bucket, [bucket.name, ...folder.key.split("/")])
        else // `bucket` 정보를 `selection`에 지정
            setSelection({bucket: bucket, files: folder.children, dir: [bucket.name, ...folder.key.split("/")]})
    }

    return (<>
        {/* 로딩 상태 blocking */}
        {loadingState && <div className="mt-5 py-2 loading-overlay">Loading.....</div>}

        {/* 저장소 상단 메뉴 */}
        <Menu />

        <Row className="mt-5 p-0">
            {/* 왼쪽 bucket 목록 */}
            {buckets && buckets.length > 0
                && <FolderTree key="left" buckets={buckets} selectBucket={selectBucket} selectFolder={selectFolder}/> }
            {/* 오른쪽 파일 목록 */}
            {selection.files && <FileLists key="right" selection={selection}  selectFolder={selectFolder} reload={reload} setLoadingState={setLoadingState}/>}

            {requestPath && selection.bucket === undefined && <NoBucketAuthority requestPath={requestPath}/>}
        </Row>
    </>)
}


function NoBucketAuthority({requestPath}) {
    // 앞뒤 / 제거 후 폴더 구분 
    const dir = requestPath.replace(/^\//gi,"").replace(/\/$/gi,"").split("/")

    return (
        <div className="col-lg-8 col-md-8 col-sm-12 p-0 mt-5 ">
            {dir[0]} 저장소에 접근 권한이 없습니다. 저장소 관리자에게 신청 하세요 !!!
        </div>
    )
}