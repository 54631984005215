// import {useCookies} from "react-cookie";
import React, {useContext} from "react";
import {Nav, NavDropdown} from "react-bootstrap";
import {UserContext} from "../App";

export default function NavbarPref(){
    const user = useContext(UserContext);
    // const [cookies, setCookie] = useCookies(["lang"]);
    
    // function changeLang(lang){
    //     if( cookies.lang !== lang ){
    //         setCookie("lang",lang);
    //         document.location.reload()
    //     }
    // }
    return(
        <Nav>
            <NavDropdown title={user.name} id="collasible-nav-dropdown1">
                <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );
}