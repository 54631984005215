import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";

export default function AddNewFolderDialog({bucket, path, setShowDialog, reload}) {
    const [folderName, setFolderName] = useState("")
    const isKor = navigator.language.includes("ko");
    const msg_ok = isKor ? "폴더가 생성 되었습니다." : "Folder is created !";
    const msg_not_login = isKor ? "로그인이 필요 합니다." : "You are not login"
    const msg_fail = isKor ? "폴더 생성이 실패 되었습니다." : "Failed to create a folder."

    useEffect(() => { // 세션 체크를 위해서
        reload(bucket, path)
    }, []);

    return(
        <Modal centered={true} show={true} onHide={() => setShowDialog(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{path.length > 1 ? bucket.groupName+" > " : bucket.groupName} {path.slice(1).filter(d => d !== "").join(" > ")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Folder Name</Form.Label>
                    <Form.Control type="text" onChange={handleInputChange} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={createFolder} title={isKor ? "입력한 이름으로 폴더를 생성 합니다." : "Create a folder with the input name."}>
                    Create Folder
                </Button>
                <Button variant="secondary" onClick={() => setShowDialog(false)} title={isKor ? "팝업 창을 닫습니다." : "Close the popup"}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );

    function handleInputChange(e) {
        setFolderName(e.target.value)
    }

// 폴더 키를 생성하는 유틸리티 함수
    function getFolderKey(path, folderName) {
        return path.length > 1 ? path.slice(1).filter(d => d !== "").join("/") + "/" + folderName : folderName;
    }

// 에러를 처리하는 함수
    function handleError(error, defaultMessage) {
        console.error(error);
        alert(defaultMessage + `- ${error}`);
        if (error.message === 'Failed to fetch') {
            window.location = "/";
        }
    }

// 응답을 처리하는 함수
    function handleResponse(response) {
        if (response.ok) {
            alert(msg_ok);
            reload(bucket, path);
        } else if (response.url.includes("/login")) {
            alert(msg_not_login);
            window.location = "/";
        } else {
            console.error(response);
            alert(msg_fail + `- ${response.statusText}`);
        }
    }

// 폴더를 생성하는 메인 함수
    async function createFolder() {
        try {
            const folderKey = getFolderKey(path, folderName);
            console.debug(`folder key : ${folderKey}`);
            const url = `/s3/bucket/${path[0]}/createFolder`;

            // PUT 요청의 body로 데이터를 전달
            const body = JSON.stringify({
                profile: bucket.profile,
                path: folderKey
            });

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json' // JSON 데이터임을 명시
                },
                body: body
            });

            handleResponse(response);
        } catch (error) {
            handleError(error, msg_fail);
        }
    }


}