import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {callApi} from "../App";

export default function SendUrlDialog({bucketName, path, setShowDialog}) {
    const [share, setShare] = useState({});
    const [expire, setExpire] = useState(0)
    const isKor = navigator.language.includes("ko");
    const msg_basic = isKor ? "위 URL을 복사하여 전달 하세요." : "Please send the above URL."

    useEffect(() => {
        callApi(`/s3/bucket/${bucketName}/downloadUrl?key=${path}`, setUrlInfo)
    }, [bucketName, path])

    return (
        <Modal centered={true} show={true} onHide={() => setShowDialog(false)}>
            <Modal.Header closeButton>
                <Modal.Title>File Download URL Generator</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Download URL(<a href="https://oneid.bwg.co.kr" target="oneid">OneID</a> 필요)</Form.Label>
                    <Form.Control type="text" className="mb-3" value={share.link} placeholder="Please wait until display URL..."/>
                    {path.endsWith(".mp4") && <>
                        <Form.Label>Movie Play URL</Form.Label>
                        <Form.Control type="text" className="mb-3" value={share.link+"&isPreview=true"} placeholder="Please wait until display URL..."/>
                    </>
                    }
                    <Form.Label>1 회용 Download URL</Form.Label>
                    <Form.Control type="text" className="mb-3" value={share.shorten} placeholder="Please wait until display URL..."/>
                    <Form.Label>1 회용 Download 만료 Date/Time</Form.Label>
                    <Form.Control type="text" className="mb-3"
                                  value={share.expire ? share.expire.substring(0, 19).replace("T", " ") : ""}/>
                    <Form.Label>1 회용 Download 만료 Date/Time 변경 </Form.Label>
                    <Form.Select onChange={(e) => setExpire(e.target.value)}>
                        <option value={1}>1 hour</option>
                        <option value={2}>2 hour</option>
                        <option value={3}>3 hour</option>
                        <option value={4}>4 hour</option>
                        <option value={5}>5 hour</option>
                        <option value={6}>6 hour</option>
                        <option value={7}>7 hour</option>
                        <option value={24}>1 day</option>
                        <option value={48}>2 day</option>
                        <option value={72}>3 day</option>
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <span className="me-auto">{msg_basic}</span>
                <Button variant="primary" onClick={changeExpire} title="Change the expire date/time.">
                    Change the expire
                </Button>
                <Button variant="secondary" onClick={() => setShowDialog(false)} title="Close the popup.">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );

    function changeExpire() {
        setShare({url: ""})
        callApi(`/s3/bucket/${bucketName}/downloadUrl?key=${path}&expire=${expire}`, setUrlInfo)
    }

    function setUrlInfo(result) {
        const shorten = window.location.port !== ""
            ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/d/${result.shorten}`
            : `${window.location.protocol}//${window.location.hostname}/d/${result.shorten}`
        if (window.location.hostname === "localhost" || window.location.hostname === "storage.bwg.co.kr")
            setShare({
                shorten: shorten,
                expire: result.expire,
                link: shorten.replace(`/d/${result.shorten}`,`/s3/bucket/${bucketName}/download?key=${result.compress}`)
            });
        else
            setShare({url: `https://storage.bwg.co.kr/d/${result.url}`, expire: result.expire});
    }
};
