import React, {useEffect, useRef, useState} from "react"
import {DataGrid} from '@mui/x-data-grid';
import {
    AppBar,
    Box,
    Card,
    CardMedia,
    IconButton,
    LinearProgress,
    Link,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {Alert} from "@mui/lab";
import {callApi, deleteApi, genPathUrl, saveApi} from "../App";
import FileUploadDialog from "./FileUploadDialog";
import SendUrlDialog from "./SendUrlDialog";
import AddNewFolderDialog from "./AddNewFolderDialog";
import FileListToolbar from "./FileListToolbar";
import {
    Archive,
    Article,
    BarChart,
    ContentCopy,
    Draw,
    Folder,
    FolderZip,
    Forward10Outlined,
    Image,
    InsertDriveFile,
    LocalMovies,
    Newspaper,
    PauseCircleOutline,
    PictureAsPdf,
    PlayArrowOutlined,
    Replay10Outlined,
} from "@mui/icons-material";


const columns = [
    {
        field: 'type', headerName: 'Type', width: 40, align: 'center', headerAlign: 'center',
        renderCell: (params) => {
            if (params.row.type === 'dir') return <Folder color="warning" title="Folder" />;
            if (params.row.name.endsWith('.mp4')) return <LocalMovies color="primary" />;
            if (params.row.name.endsWith('.zip') || params.row.name.endsWith('.gz') || params.row.name.endsWith('.tar')) return <FolderZip color="primary" />;
            if (params.row.name.endsWith('.jar')) return <Archive color="primary" />;
            if (params.row.name.endsWith('.doc')) return <Article color="primary" />;
            if (params.row.name.endsWith('.docx')) return <Article color="primary" />;
            if (params.row.name.endsWith('.xls')) return <BarChart color="primary" />;
            if (params.row.name.endsWith('.xlsx')) return <BarChart color="primary" />;
            if (params.row.name.endsWith('.ppt')) return <Newspaper color="primary" />;
            if (params.row.name.endsWith('.pptx')) return <Newspaper className="text-primary" />;
            if (params.row.name.endsWith('.drawio')) return <Draw className="text-primary" />;
            if (params.row.name.endsWith('.pdf')) return <PictureAsPdf className="text-primary" />;
            if (params.row.name.endsWith('.jpg')) return <Image className="text-primary" />;
            if (params.row.name.endsWith('.svg')) return <Image className="text-primary" />;
            if (params.row.name.endsWith('.png')) return <Image className="text-primary" />;
            if (params.row.name.endsWith('.ai')) return <Image className="text-primary" />;
            return <InsertDriveFile color="primary" />;
        }
    },
    { field: 'name', headerName: 'Name', width: 200, flex: 1 },
    {
        field: 'size', headerName: 'Size', width: 100, align: 'right', headerAlign: 'center',
        valueGetter: (params) =>
            `${params.row.size > 1024 * 1024
                ? Math.ceil(params.row.size / (1024 * 1024)).toLocaleString() + " MB"
                : Math.ceil(params.row.size / 1024) + " KB"}`
    },
    {
        field: 'date', headerName: 'Last Modified', width: 200,
        valueGetter: (params) => `${params.row.date == null ? "" : params.row.date.replace("T", " ").replace("Z", "")}`
    },
];

export default function FileLists({ selection, reload, setLoadingState, selectFolder }) {
    const previewExt = [".mp4", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".jpg", ".png", ".pdf"]
    const officeExt = [".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx"]
    const [rows, setRows] = useState([]);
    const [path, setPath] = useState([])
    const [selectedItems, setSelectedItems] = useState([]);
    const [uploadDialogFlag, setUploadDialogFlag] = useState(false);
    const [sendDialogFlag, setSendDialogFlag] = useState(false);
    const [folderDialogFlag, setFolderDialogFlag] = useState(false);
    const [play, setPlay] = useState(false)
    const [alertMsg, setAlertMsg] = useState({msg:""})
    const getRowId = (row) => row.key;
    const isKor = navigator.language.includes("ko");
    const [isPlaying, setIsPlaying] = useState(true);
    const videoRef = useRef(null);
    const [progress, setProgress] = useState({rate:0, current:0});
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [timerId, setTimerId] = useState(0)
    const [cutObjects, setCutObjects] = useState([])
    const tooltip_path_copy = isKor ? "폴더 경로 URL을 복사합시다." : "Copy URL of the folder path."

    /**
     * 동영상 진행 바 클릭 시 해당 위치로 이동
     *
     * @param event
     */
    const handleProgressClick = (event) => {
        const clickPosition = event.nativeEvent.offsetX;
        const totalWidth = event.target.clientWidth;
        videoRef.current.currentTime = (clickPosition / totalWidth) * videoRef.current.duration;
    };

    /**
     * 지정한 시간(초) 만큼 동영상 재생 앞으로 이동
     *
     * @param seconds 이동 시간
     */
    const skip = (seconds) => {
        if (videoRef.current) {
            videoRef.current.currentTime += seconds;
        }
    };

    /**
     * 동영상 재생 시간 계산
     *
     * @param duration 남은 시간
     * @returns {`${string|string}${string}${string|number}`}
     */
    const formatDuration = (duration) => {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = Math.floor(duration % 60);

        const hoursDisplay = hours > 0 ? `${hours}:` : '';
        const minutesDisplay = `${minutes < 10 ? '0' : ''}${minutes}:`;
        const secondsDisplay = seconds < 10 ? `0${seconds}` : seconds;

        return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`;
    };

    /**
     * 재생 시간 update
     */
    const handleTimeUpdate = () => {
        const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
        const current = formatDuration(videoRef.current.currentTime)
        setProgress({rate: progress, current: current});
    };


    useEffect(() => {
        console.debug("[FileLists.useEffect]", selection.files)
        setPath([...selection.dir])
        // 파일 목록이 있을 때만 `DataGrid`에 데이터 지정
        if (selection.files) { // 파일 목록 갱신
            const list = Object.values(selection.files);
            if( selection.dir.length === 1 ) setRows(list)
            else{
                setRows([{name: "..", size: 0, type: "dir", key: selection.dir.filter(d => d !== "").slice(0,-1).pop(), children: {} },...list])
            }
        }
        setPlay({ status: false, url: "" }); // 동영상 재생 초기화
    }, [selection]) // 파일 목록이 변경 되면 다시 지정

    useEffect(() => {
        // 다운로드 시작/종료 되었으면 메시지를 10초 후에 disappear
        if (alertMsg.msg > "" && (downloadProgress === 0 || downloadProgress === 100)) {
            if( timerId ) clearTimeout(timerId)
            const id = setTimeout(() => {
                setAlertMsg({msg:""});
            }, 10000);
            setTimerId(id)
        }
    }, [alertMsg])


    function handlePlayPause() {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    }

    // toolbar에서 reload를 호출하면, goTo에서 경로가 selection.dir에 반영되지 않아서 하위 폴더를 refresh 함.  
    function reloadFileList(){
        reload(null, path)
    }

    function goTo(dir) {
        console.debug("goTo : ", dir)
        const idx = path.indexOf(dir);
        let index = 1;
        let folder = selection.bucket
        let newPath = []
        while (true) {
            if (folder.name === path[idx]) {
                setPath([...newPath, folder.name])
                if( window.location.href.includes("?path=") && Object.values(folder.children).length === 1)
                    reload(selection.bucket,[...newPath, folder.name] )
                else {
                    const list = Object.values(folder.children)
                    if( folder.name === selection.bucket.name )
                        setRows(list)
                    else{
                        setRows([{name: "..", type: "dir", key: newPath.pop() , children: {} },...list])
                    }
                }
                break;
            }
            newPath = [...newPath, folder.name];
            folder = folder.children[path[index]];
            if (folder === undefined) {
                const msg_err = isKor ? `${path[index]} 에 해당하는 객체를 찾을 수 없습니다.` : `There is no object of ${path[index]}`
                setAlertMsg({ severity: "error", msg: msg_err })
                break;
            }
            index++;
        }
    }

    function checkDownload() {
        if (selectedItems.length === 0) return true;
        return Object.values(rows).filter(file => selectedItems.includes(file.key))
            .filter(file => file.type === 'dir').length > 0
    }
    function download() {
        console.debug("selected : ", selectedItems)
        const sel = Object.values(rows).filter(file => selectedItems.includes(file.key))
        console.debug("download ", sel)
        if(sel.length === 1 && window.confirm(isKor ? `"${sel[0].name}" 파일을 다운로드 하시겠습니까?` : `Do you want to download "${sel.name}"?`) === false) return;
        if(sel.length > 1 && window.confirm(isKor ? `${sel.length}개의 파일을 다운로드 하시겠습니까?` : `Do you want to download ${sel.length} files?`) === false) return;
        fetchDownloadUrl(sel)
    }

    function fetchDownloadUrl(list, index = 0, isPreview = false) {
        if (index >= list.length) {
            setDownloadProgress(0)
            downloadFileSequentially(list)
            return;
        }
        setLoadingState(true)
        console.debug("download file key : ", list[index].key)
        const url = `/s3/bucket/${selection.bucket.name}/downloadUrl?key=${encodeURIComponent(list[index].key)}&isPreview=${isPreview}`
        callApi(url, (result) => {
            list[index].url = result.url
            fetchDownloadUrl(list, index + 1)
        }, null, genPathUrl(selection.bucket.name, path.slice(1).join("/")))
    }

    // 파일 다운로드를 위한 함수
    function downloadFileSequentially(files, index = 0) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', files[index].url, true);
        xhr.responseType = 'blob';

        // 진행 상태 업데이트
        xhr.onprogress = function(event) {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setDownloadProgress(percentComplete)
                //console.log('Download Progress: ' + percentComplete.toFixed(2) + '%');
            }
        };

        // 다운로드 완료 처리
        xhr.onload = function() {
            if (this.status === 200) {
                const blob = new Blob([this.response], { type: 'application/octet-stream' });
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = files[index].name;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(downloadUrl);
            }
            if( index+1 < files.length) { // 다음 파일
                downloadFileSequentially(files, index + 1)
            }else {
                setLoadingState(false)
                setAlertMsg({severity: "info", msg: "Download complete!!"});
            }
        };

        // 에러 처리
        xhr.onerror = function() {
            console.error('Download error');
        };

        xhr.send();
        setAlertMsg({ severity: "info", msg: `${files[index].name} downloading....` })
    }

    function checkExt() {
        if (selectedItems.length === 0) return true;
        const target = Object.values(rows).filter((file) => selectedItems.includes(file.key))[0]
        if (target === undefined || target.type === 'dir') return true;
        const ext = target.name.substring(target.name.lastIndexOf("."))
        return !previewExt.includes(ext)
    }

    function preview(row) {
        if (row === undefined && checkExt()) {
            const msg_select = isKor ? "미리 보기가 가능한 파일(office, mp4 등) 하나만 선택 하세요!!" : "Please select only one file that can be previewed (e.g., office, mp4)."
            setAlertMsg({ severity: "error", msg: msg_select })
            return;
        }
        const file = row === undefined ? Object.values(rows).filter((file) => selectedItems.includes(file.key))[0] : row

        if(file.key.endsWith(".mp4"))
        {
            const url1 = `/s3/bucket/${selection.bucket.name}/downloadUrl?key=${encodeURIComponent(file.key)}`;
            callApi(url1, (result) => {
                        let url = window.location.port !== ""
                            ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
                            : `${window.location.protocol}//${window.location.hostname}`
                        url += `/s3/bucket/${selection.bucket.name}/download?key=${result.compress}`
                        setPlay({ status: true, url: url })
                    }, null, genPathUrl(selection.bucket.name, path.slice(1).join("/")))
        }
        else{
            if( !navigator.userAgent.includes("Edg") ){
                const lIndex = file.key.lastIndexOf(".")
                const ext = file.key.substring(lIndex)
                if( officeExt.includes(ext) ) alert("Office 파일의 미리보기는 Edge Browser에서만 가능합니다.")
            }
            fetchDownloadUrl([file], 0, true)
        }
    }

    /**
     * DataGrid의 행을 선택 했을 때 실행 됨.
     *
     * @param newSelection String[]
     */
    function handleSelectionModelChange(newSelection) {
        console.debug("handleSelectionModelChange : ", newSelection)
        if( newSelection.name === ".." ) return ;
        setSelectedItems(newSelection);
    }

    function handleCellDoubleClick(cell) {
        const ext = cell.row.name.substring(cell.row.name.lastIndexOf("."))
        console.debug("handleCellDoubleClick: ", ext, cell)
        const row = cell.row
        if( row.name === "..") goTo(row.key)
        else if (row.type === 'dir') selectFolder(selection.bucket, row)
        // else downloadFile(row, previewExt.includes(ext))
        else if (previewExt.includes(ext)) preview(row)
        else if( window.confirm(`Do you want to download the "${row.name}" ?`) ) fetchDownloadUrl([row])
    }

    function getKey(keys, isSameFolder) {
        if (isSameFolder) return Object.values(rows).filter((file) => keys.includes(file.key));
        return keys.map((key) => {
            const fileName = key.substring( key.lastIndexOf("/")+1 )

            return {name: fileName, type: "file", key: key, children: {} }
        })

    }

    /**
     * 삭제 : 파일 move로 삭제하는 경우는 현재 폴더가 아니므로 대상을 받는다.
     * @param input 삭제 대상 파일 목록, 파일 선택 삭제는 값이 없다.
     * @returns {boolean}
     */
    function deleteFiles(input) {
        const keys = input === undefined ? selectedItems : input
        if (keys.length === 0) return true;
        const key = getKey(keys, input === undefined)
        if (key.filter(file => file.type === 'dir').filter(file => Object.values(file.children).length > 0)// 하위 항목이 있는  디렉토리
            .flatMap(file => Object.values(file.children)) // 하위 항목들을 모아서
            .filter(file => file.type === 'dir').length > 0) { // 폴더가 있으면
            const names = key.filter(file => Object.values(file.children).length > 0).map(file => file.name).join(", ")
            const msg_err = isKor ? `하위 폴더가 있는 경우 삭제 되지 않습니다. 하위 폴더를 먼서 삭제하세요. - ${names}`
                : `If the folder has subfolders, it will not be deleted. Please delete the subfolders first. - ${names}`
            setAlertMsg({ severity: "error", msg: msg_err });
            return;
        }
        const msg_confirm = isKor ? "폴더/파일(들)을 삭제 하시겠습니까?" : "Do you want to delete the folder/file(s)?"
        const doDelete = input === undefined ? window.confirm(`${key.map(file => file.name).join(", ")} ${msg_confirm}`) : true
        if (doDelete)
            deleteApi(`/s3/bucket/${selection.bucket.name}/deletes?profile=${selection.bucket.profile}`, key, () => {
                // file move는 삭제 알람을 하지 않는다.
                if(input === undefined ) {
                    setAlertMsg({ severity: "success", msg: `${key.map(file => file.name).join(", ")} - Deleted` });
                    reload(selection.bucket, path);
                }else{ // move, 삭제한 폴더 refresh, 현재 위치도 refresh
                    // reload 내에서 slice(1)을 해서 bucket.name 값을 제외하고 있어 목록을 맞춰줌
                    reload(selection.bucket, [ selection.bucket.name, ...input[0].split("/").slice(0, -1)], false)
                    reload(selection.bucket, path)
                }
            }, key);
    }

    function copyToClipboard() {
        const value = genPathUrl(selection.bucket.name, path.slice(1).join("/"))
        console.debug("value : ", value)

        navigator.clipboard.writeText(value)
        const copy_path = isKor ? "URL을 복사 했습니다." : "URL is copied."
        setAlertMsg({ severity: "info", msg: copy_path })
    }

    /**
     * 선택한 파일(들)을 이동/복사 하기 위해 key목록을 별도 저장 둔다.
     */
    function cutObject(state){
        if( selectedItems.length === 0 ) return;
        setCutObjects([...selectedItems])
        const action = state === 1 ? (isKor ? "복사" : "copy") : (isKor ? "이동" : "move")
        if(isKor)
            setAlertMsg({ severity: "info", msg: `파일(들)을 ${action} 하고 싶은 폴더로 이동하여 붙여넣기 하세요!!` })
        else
            setAlertMsg({ severity: "info", msg: `Go to folder to ${action} file(s) and PASTE!!` })
    }

    function pasteObject(state){
        const destination = path.slice(1).join("/")
        if( destination === cutObjects[0].substring(0, cutObjects[0].lastIndexOf("/")+1)) {
            if(isKor)
                setAlertMsg({ severity: "warning", msg: `같은 폴더에는 붙여넣기 할 수 없습니다!!` })
            else
                setAlertMsg({ severity: "warning", msg: `You cannot paste same folder!!` })
            return;
        }
        const url = `/s3/bucket/${selection.bucket.name}/copy`
        const data = {profile: "default", source: cutObjects, destination: destination}
        saveApi(url, "POST", data, (result) => {
            console.debug("copy result : ", result)
            // 삭제 후 대상 폴더 refresh
            if(state === 2) deleteFiles(cutObjects)
            // 현재(destination) 폴더를 refresh
            else reload(selection.bucket, path);
            setCutObjects([])
            if(isKor)
                setAlertMsg({ severity: "info", msg: `파일(들)을 옮겼습니다. 이전 폴더에 파일(들)이 보이면 목록을 갱신하세요!!` })
            else
                setAlertMsg({ severity: "info", msg: "Files have been moved. If files still appear in the previous folder, please refresh the list!" })
        })
    }

    function checkPaste(){
        if( cutObjects.length === 0 ) return true;
        let files = selection.bucket.children
        const dir = cutObjects[0].split("/")
        let i = 0;
        while(i < dir.length-1){
            if( files[dir[i]] === undefined ) return true;
            files = files[dir[i]].children
            i++
        }
        return false
    }


    return (<>
            <div className="col-lg-8 col-md-8 col-sm-12 p-0 mt-5 pb-5">
                {/* 알림 메시지 */}
                {alertMsg.msg > "" && <Alert severity={alertMsg.severity} onClose={() => { setAlertMsg("") }}>
                    {alertMsg.msg}
                </Alert>}
                {/* 다운로드 진행 바 */}
                {downloadProgress > 0 && downloadProgress < 100 && <div style={{ width: '100%' }}>
                    <progress value={downloadProgress} max="100" style={{ width: '100%' }} />
                </div>
                }

                {/* 현재 위치 */}
                <AppBar position="static" >
                    <Toolbar>
                        <Tooltip title={tooltip_path_copy} placement="bottom">
                        <ContentCopy onClick={() => copyToClipboard()} />
                        </Tooltip>
                        <Typography component="div" sx={{ flexGrow: 1 }}>
                            {path.filter((dir) => dir.length > 0).map((dir, index) => (<>
                                {index > 0 ? " > " : ""}
                                <Link href="#" className="text-white" key={index}
                                      onClick={() => goTo(dir)}>{index > 0 ? dir : selection.bucket.groupName}</Link></>))}
                        </Typography>
                    </Toolbar>
                </AppBar>

                {/* Toolbar */}
                <FileListToolbar bucket={selection.bucket}
                                 selectedItems={selectedItems} download={download} preview={preview}
                                 checkExt={checkExt} addFolder={setFolderDialogFlag} deleteFiles={deleteFiles}
                                 checkDownload={checkDownload} reloadFileList={reloadFileList} shareUrl={setSendDialogFlag}
                                 uploadFile={setUploadDialogFlag} cutObject={cutObject} pasteObject={pasteObject}
                                 checkPaste={checkPaste}
                />
                {/* 파일 목록 */}
                <Box sx={{ height: '90%', width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10, 20, 30, 40, 50, 100]}
                        columns={columns}
                        checkboxSelection={true}
                        getRowId={getRowId}
                        rowSelectionModel={selectedItems}
                        onRowSelectionModelChange={handleSelectionModelChange}
                        onCellDoubleClick={handleCellDoubleClick}
                        sx={{
                            boxShadow: 2,
                            border: 2,
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                        }}
                    />
                </Box>
                {/* 파일 업로드 dialog */}
                {uploadDialogFlag && (
                    <FileUploadDialog
                        bucket={selection.bucket}
                        setShowDialog={setUploadDialogFlag}
                        reload={reload}
                        dir={path}
                    />
                )}
                {/* URL 조회 dialog */}
                {sendDialogFlag && (
                    <SendUrlDialog
                        bucketName={selection.bucket.name}
                        path={Object.values(rows).filter(file => selectedItems.includes(file.key))[0].key}
                        setShowDialog={setSendDialogFlag}
                    />
                )}

                {/* 폴더 만들기 dialog */}
                {folderDialogFlag && (
                    <AddNewFolderDialog
                        bucket={selection.bucket}
                        setShowDialog={setFolderDialogFlag}
                        path={path}
                        reload={reload}
                    />
                )}
            </div>
            {/* 동영상 재생 */}
            {play.status &&
                <Card className="p-4 mt-5">
                    {/* 동영상 */}
                    <CardMedia component="video" ref={videoRef} src={play.url} autoPlay
                               onContextMenu={(event) => event.preventDefault()}
                               onTimeUpdate={handleTimeUpdate}>
                        {/* video content here */}
                    </CardMedia>
                    {/* 진행 바 */}
                    <LinearProgress sx={{ height: 10 }} variant="determinate" value={progress.rate} onClick={handleProgressClick} />
                    {/* 재생 시간 */}
                    [{videoRef && videoRef.current && progress.current +"/"+ formatDuration(videoRef.current.duration)}]
                    {/* 제어 버튼 */}
                    <IconButton onClick={handlePlayPause}>
                        {isPlaying ? <PauseCircleOutline /> : <PlayArrowOutlined />}
                    </IconButton>
                    <IconButton onClick={() => skip(-10)}>
                        <Replay10Outlined />
                    </IconButton>
                    <IconButton onClick={() => skip(10)}>
                        <Forward10Outlined />
                    </IconButton>
                </Card>
            }
        </>
    );


}

