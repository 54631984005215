import React from 'react';

function Login() {

    return (
        <header className="masthead">

            <div className="container px-3 px-lg-4 h-100">

                <div className="row gx-4 gx-lg-5 h-100 align-items-end justify-content-end text-center">

                    <div className="col-lg-8 align-self-end">
                        <p className="text-black-75 fs-1 mb-0">BwG Developers Portal</p>
                        <hr className="divider"/>
                        <a className="btn btn-primary btn-xl"
                           href="/oauth2/authorization/keycloak" >Login</a>
                        {/* <a className="btn btn-primary btn-xl"
                           href="/oauth2/authorization/gitlab" >Login</a> */}
                    </div>
                    <div className="col-lg-8 align-self-baseline d-block">
                         <p className="text-warning-75 mb-5"></p>

                    </div>
                </div>
            </div>
        </header>
    );
}


export default Login;