import {Tooltip} from "@mui/material";
import {Button} from "react-bootstrap";
import {
    CloudUploadOutlined,
    ContentCut,
    ContentPasteGo,
    CopyAllOutlined,
    CreateNewFolder,
    Delete,
    FileDownload,
    FileUpload,
    ManageAccounts,
    Refresh,
    ShareOutlined,
    Visibility
} from "@mui/icons-material";
import React, {useState} from "react";

export default function FileListToolbar(props) {
    const [copyState, setCopyState] = useState(0);
    const accessLevel = props.bucket === undefined ? 0 : props.bucket.accessLevel;
    // const hasAuth = user.roles.filter((r) => r.includes(bucket.name)).length > 0;
    const isKor = navigator.language.includes("ko");

    const tooltip_preview = isKor ? "오피스파일, 동영상 등 선택한 파일을 미리보기 합니다." : "Preview selected files such as office files and videos."
    const tooltip_download = isKor ? "선택한 파일을 다운로드 합니다." : "Download the selected file(s)."
    const tooltip_share = isKor ? "선택한 파일의 공유 URL을 생성 합니다." : "Create the sharing URL of the selected file."
    const tooltip_folder = isKor ? "새로운 폴더를 추가 합니다." : "Add a new folder."
    const tooltip_upload = isKor ? "현재 위치에 파일을 업로드 합니다." : "Upload the file to the current location."
    const tooltip_s3 = isKor ? "S3 Bucket으로 직접 작업 합니다." : "Work directly with S3 Bucket."
    // const tooltip_user = isKor ? "사용자를 관리 합니다." : "Manage the users authority."
    const tooltip_gitlab = isKor ? "사용자를 관리 위한 gitlab으로 이동합니다." : "Open gitlab group to manage users"
    const tooltip_refresh = isKor ? "파일 목록을 새로 불러 옵니다." : "Refresh the file lists."
    const tooltip_delete = isKor ? "선택한 파일(들)을 삭제합니다." : "Delete the selected file(s)"
    const msg_aws = isKor ? "로그인 양식이 보이거나, 권한이 없다면, 창을 닫고 메뉴의 '로그인'을 선택해서 로그인 하거나 필요한 권한을 선택 하세요!!!"
                          : "If you see a login form or you don't have the permissions, close the window and select 'AWS Login from the menu to login or select the required permissions."

    const tooltip_cut = isKor ? "파일(들)을 이동하기 위해 보관해 둡니다." : "Store the file(s) to move"
    const tooltip_copy = isKor ? "파일(들)을 복사하기 위해 보관해 둡니다." : "Store the file(s) to copy"
    const tooltip_paste = isKor ? "보관해 둔 파일(들)을 현재 위치에 붙여넣기 합니다." : "Paste the file(s) to the current location"

    function copyObject(state) {
        setCopyState(state)
        props.cutObject(state)
    }

    function pasteObject() {
        props.pasteObject(copyState)
        setCopyState(0)
    }


    return (
        <div className="m-2">
            <Tooltip title={tooltip_preview} placement="bottom">
                <Button className="ms-1" variant={props.selectedItems.length > 1 || props.checkExt() ? "light" : "info"}
                        onClick={() => props.preview()} disabled={props.selectedItems.length > 1 || props.checkExt()}
                ><Visibility/></Button>
            </Tooltip>
            <Tooltip title={tooltip_download} placement="bottom">
                <Button className="ms-1"  variant={props.checkDownload() ? "light":"info"} onClick={props.download}
                        disabled={props.checkDownload()}
                ><FileDownload/></Button>
            </Tooltip>
            {accessLevel > -1 && <Tooltip title={tooltip_share} placement="bottom">
                <Button className="ms-1" variant={props.selectedItems.length > 1 || props.checkDownload() ? "light":"info"}
                        onClick={() => props.shareUrl(true)} disabled={props.selectedItems.length > 1 || props.checkDownload()}
                ><ShareOutlined/></Button>
            </Tooltip>}

            {accessLevel > 20 && <Tooltip title={tooltip_copy} placement="bottom">
                <Button className="ms-1" variant={ props.checkDownload() ? "light": copyState === 1 ? "success":"info"}
                        onClick={() => copyObject(1)} disabled={ props.checkDownload() }
                ><CopyAllOutlined/></Button>
            </Tooltip>}

            {accessLevel > 20 && <Tooltip title={tooltip_cut} placement="bottom">
                <Button className="ms-1" variant={ props.checkDownload() ? "light": copyState === 2 ? "success":"info" }
                        onClick={() => copyObject(2)} disabled={ props.checkDownload()}
                ><ContentCut/></Button>
            </Tooltip>}

            {accessLevel > 20 && <Tooltip title={tooltip_paste} placement="bottom">
                <Button className="ms-1" variant={ props.checkPaste() ? "light":"info"}
                        onClick={pasteObject} disabled={props.checkPaste()}
                ><ContentPasteGo/></Button>
            </Tooltip>}

            {accessLevel > 20 && <Tooltip title={tooltip_folder} placement="bottom">
                <Button className="ms-1" variant="info" onClick={() => props.addFolder(true)}><CreateNewFolder/></Button>
            </Tooltip>}
            {accessLevel > 20 && <Tooltip title={tooltip_upload} placement="bottom">
                <Button className="ms-1" variant="info" onClick={() => props.uploadFile(true)}><FileUpload/></Button>
            </Tooltip>}

            {accessLevel > 20 && <Tooltip title={tooltip_s3} placement="bottom">
                <Button className="ms-1" variant="info" onClick={gotoAws}><CloudUploadOutlined/></Button>
            </Tooltip>}
            {/* {accessLevel > 30 && <Tooltip title={tooltip_user} placement="bottom">
                <Button className="ms-1" variant="info" onClick={manageUser}><ManageAccounts/></Button>
            </Tooltip>} */}
            {accessLevel > 30 && <Tooltip title={tooltip_gitlab} placement="bottom">
                <Button className="ms-1" variant="info" onClick={() => window.open(`https://git.bwg.co.kr/gitlab/${props.bucket.full_path}`,"_gitlab")}><ManageAccounts/></Button>
            </Tooltip>}

            {/*{hasAuth && <Tooltip title="S3 Bucket으로 직접 작업 합니다." placement="bottom">*/}
            {/*    <Button className="ms-1" variant="info" onClick={gotoAws}><CloudUploadOutlined/></Button>*/}
            {/*</Tooltip>}*/}
            <Tooltip title={tooltip_refresh} placement="bottom">
                <Button size="small" className="ms-1" variant="info" onClick={props.reloadFileList}><Refresh/></Button>
            </Tooltip>
            {accessLevel > 20 && <Tooltip title={tooltip_delete} placement="bottom">
                <Button className="ms-1" variant={props.selectedItems.length === 0 ? "light":"warning"} onClick={() => props.deleteFiles()}
                        disabled={props.selectedItems.length === 0}><Delete/></Button>
            </Tooltip>}
        </div>
    );

    function gotoAws() {
        alert(msg_aws);
        //https://ap-northeast-2.console.aws.amazon.com/s3/buckets/backup-daily-finlab?region=ap-northeast-2&bucketType=general&tab=objects
        window.open(
            `https://ap-northeast-2.console.aws.amazon.com/s3/buckets/${props.bucket.name}`,
            "aws"
        );
    }
}
