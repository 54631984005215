import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {callApi, putApi, saveApi} from "../App";

export default function FileUploadDialog({ bucket, setShowDialog, reload, dir }) {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loadingState, setLoadingState] = useState(false);
    const isKor = navigator.language.includes("ko");
    const [uploadProgress, setUploadProgress] = useState([]);
    // Store XMLHttpRequest references
    const xhrRefs = useRef([]);
    const [finish, setFinish] = useState(false)
    const msg_no_file = isKor ? "업로드 할 파일(들)을 선택 하세요!!" : "Please select file to upload!!"
    // bucket 이름 이후만
    const folder = dir.slice(1).filter(d => d !== "").join("/");
    const msg_not_login = isKor ? "로그인이 필요 합니다." : "You are not login"

    const handleFileInputChange = (e) => {
        setSelectedFiles(e.target.files);
        setUploadProgress(Array(e.target.files.length)); // Reset progress when a new file is chosen
        setFinish(false)
    };

    function checkProgressAndClose() {
        if (uploadProgress > 0 && uploadProgress < 100) alert("창을 닫아도 업로드는 종료되지 않습니다.")
        setShowDialog(false)
    }

    const progress = Math.round(uploadProgress.reduce((a, b) => a + b, 0) / selectedFiles.length)

    useEffect(() => {
        if( finish === false ) return
        // 업로드가 끝난 파일은 tag를 작성하다.
        const fileNames = Object.values(selectedFiles).map(f => f.name).join(",")
        saveApi(`/s3/bucket/${bucket.name}/addTag`, 'POST', {path: folder, fileNames: fileNames},
            (result) => {
                console.debug(`add tag result : ${result}`)
            })
    },[finish])

    useEffect(() => { // 세션 체크를 위해서
        reload(bucket, dir)
    }, []);

    return (<>
        <Modal centered={true} show={true} onHide={() => setShowDialog(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Upload path</Form.Label>
                    <Form.Control type={"text"} value={dir.filter(d => d !== "").join(" > ")} />
                    <Form.Label className="mt-3">Upload file</Form.Label>
                    <Form.Control type="file" onChange={handleFileInputChange} multiple />
                </Form.Group>
                {uploadProgress.length > 0 && <div style={{ width: '100%' }}>
                    <progress value={uploadProgress.reduce((a, b) => a + b, 0) / selectedFiles.length} max="100" style={{ width: '100%' }} />
                    <div style={{ textAlign: 'center' }}>
                        {progress}%
                    </div>
                </div>
                }
                {progress > 0 && 
                    uploadProgress.map((p,i) => p === 100 ? <div>{selectedFiles[i].name} is uploaded.</div> : <></>)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={upload} title="Upload the selected file" 
                    disabled={selectedFiles.length === 0 || progress > 0}>
                    Upload
                </Button>
                <Button variant="warning" onClick={cancelUpload} title="Cancel upload"
                        disabled={selectedFiles.length === 0 || progress === 0 || progress === 100}>
                    Cancel Upload
                </Button>
                <Button variant="secondary" onClick={checkProgressAndClose} title="Close the popup">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        {loadingState && <div className="loading-overlay"></div>}
    </>
    )

    function upload() {
        if (selectedFiles.length === 0) {
            alert(msg_no_file);
            return;
        }

        // const uri = `/s3/bucket/${bucket.name}/putUrls?path=${folder}&files=${Object.values(selectedFiles).map(f => f.name).join(",")}`
        const uri = `/s3/bucket/${bucket.name}/putUrls`
        console.debug("gen url : ", uri)
        // PUT 요청의 body로 데이터를 전달
        const body = {
            files: Object.values(selectedFiles).map(f => f.name).join(","),
            path: folder
        };
        putApi(uri, 'PUT', body, (result) =>{
            console.debug("start request to aws : ", result.length)
            result.map((u, index) => upload2AWS(u.url, selectedFiles[index], index))
            console.debug("finish request to aws")
        })
        // const response = await fetch(uri, {
        //     method: 'PUT',
        //     headers: {
        //         'Content-Type': 'application/json' // JSON 데이터임을 명시
        //     },
        //     body: body
        // });
        // handleResponse(response);
        // callApi(uri, (result) => {
        //     console.debug("start request to aws : ", result.length)
        //     result.map((u, index) => upload2AWS(u.url, selectedFiles[index], index))
        //     console.debug("finish request to aws")
        // })
    }


    function upload2AWS(url, file, index) {
        try {
            // Use XMLHttpRequest to upload the file
            const xhr = new XMLHttpRequest();

            xhr.open('PUT', url, true);
            xhr.setRequestHeader('Content-Type', 'multipart/form-data');

            // Event listener for upload progress
            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    uploadProgress[index] = Math.round((event.loaded / event.total) * 100);
                    setUploadProgress([...uploadProgress]);
                }
            };

            // Event listener for completion of the upload
            xhr.onload = () => {
                if (xhr.status === 200) {
                    uploadProgress[index] = 100
                    setUploadProgress([...uploadProgress]); // Set progress to 100% on successful upload
                    // reload는 모든 업로드가 끝나면.
                    if( uploadProgress.filter(p => p === 100).length === uploadProgress.length ) {
                        setFinish(true) // 모든 파일의 업로드가 끝났다.
                        reload(bucket, dir)
                    }
                } else {
                    alert('Failed to upload the file.');
                }
            };

            // Start the upload
            xhr.send(file);
            xhrRefs.current.push(xhr);
        } catch (error) {
            console.error('Error during file upload:', error);
            alert('An error occurred while uploading the file.');
            setLoadingState(false)
        }
    }

    function cancelUpload () {
        // Abort all ongoing uploads
        xhrRefs.current.forEach(xhr => xhr.abort());
        xhrRefs.current = []; // Reset the refs after canceling
        setAlertMsg({ msg: "Upload canceled....", show: true })
    }
}