import React from 'react'
import {Nav, Navbar} from "react-bootstrap";
import NavbarPref from "./NavbarPref";

export default function Menu({requestRepository}){
    //const context = useContext(MainContext)
    return (
        <div className="main-navbar bg-light">
            <div className="container-fluid p-0">
                <Navbar bg="light" variant="light" fixed={"top"}>
                    <div className="container-fluid">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link className="fs-6" onClick={() => goToAwsLogin()} key={"aws"}
                                          title="AWS login to bulk upload">
                                    {"AWS Login"}
                                </Nav.Link>
                                <Nav.Link className="fs-6" key={"request"} onClick={requestRepository}
                                          title="Request New Storage">
                                    {"Request S3"}
                                </Nav.Link>
                                <Nav.Link className="fs-6" key={"ec2"} href="https://systems.bwg.co.kr/oauth2/authorization/keycloak"
                                          title="Control the ec2 servers.">
                                    {"Systems"}
                                </Nav.Link>
                                <Nav.Link href="https://git.bwg.co.kr/gitlab/feedback/system/-/issues/new"
                                          target="feedback" key={"feedback"} className="fs-6"
                                          title="Issue your feedback ">
                                    {"Feedback"}
                                </Nav.Link>
                                <Nav.Link href="https://pages.bwg.co.kr/finlab/docs/delivery-s3" target="help"
                                          className="fs-6" key={"help"} title="Open user guide">
                                    {"Help"}
                                </Nav.Link>
                            </Nav>
                            <NavbarPref/>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </div>
        </div>
    )
}

function goToAwsLogin() {
    alert("로그인이 확인 되면 창을 닫아 주세요")
    window.open(`https://oneid.bwg.co.kr/realms/finlab/protocol/saml/clients/amazon-aws`, "aws_login", "modal=yes, dialog=yes, width=800, height=500")
}