import React, {createContext, useEffect, useState} from 'react';
import Main from './Main';

export const UserContext = createContext(undefined);

function App() {
    const [user, setUser] = useState({})

    useEffect(() => {
        // 없으면 두번 조회 함. 최초 한번, 변경되고 또한번 조회함. 이미 조회한 경우 다시 조회할 필요가 없어 추가 함.
        if (user.name !== undefined) return;

        console.debug("[App.useEffect]", user);
        callApi("/user", setUser, {name: "Guest", menu: [], isAdmin: false})
    }, [user])

    return (
        <UserContext.Provider value={user}>
            <Main/>
        </UserContext.Provider>
    );
}


export function callApi(url, setter, defaultValue, path) {
    // Simplified fetch call
    fetch(url, {
        redirect: 'manual',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Custom-Referrer': window.location.href
        }
    }).then(handleResponse) // Handling response in a separate function
      .then(handleResult)   // Handling result in a separate function
      .catch(handleError);  // Handling errors in a separate function

    function handleResponse(response) {
        console.debug(`response.type : ${response.type}`);
        console.debug(`response.redirected : ${response.redirected}`);
        console.debug(`response.status : ${response.status}`);

        // Simplified conditionals
        if (response.type === "opaqueredirect") {
            alert("You need login");
            window.open(path);
            return Promise.reject('Redirected: Login required');
        } else if (response.redirected) {
            window.location.href = "/";
            return Promise.reject('Redirected: Root');
        }

        return response.json();
    }

    function handleResult(result) {
        if (!result) return;

        console.debug(`[callApi-${url}] result status : ${result.status}`);

        if (result.status && result.status >= 400) {
            const errorMsg = `${result.path} : ${result.message}` + (result.trace ? ":\n\n콘솔에서 trace를 확인하세요" : "");
            console.error(result);
            console.error(result.trace);
            setter(errorMsg);
            return;
        }

        setter(result);
    }

    function handleError(e) {
        console.error(`fetch error : ${e}`);
        alert(e);
        if (defaultValue) setter(defaultValue);
        if (e.message === 'Failed to fetch') window.location.href = "/";
    }
}

export function putApi(uri, method, data, callback){
    saveApi(uri, method, data, callback);
}
export function saveApi(uri, method, data, callback) {
    fetch(uri, {
        method: method,
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    }).then(response => {
        console.debug(`response : ${response.status} , ${response.redirected}`)
        if (response.redirected === true) {
            window.location.href = "/"
        }
        return response.json()
    }).then((json) => {
        if (json.status >= 400) {
            throw json.message
        }
        callback(json)
    }).catch((error) => {
        console.error(error);
        if (error.message === 'Failed to save') window.location = "/"
        else alert(`저장에 실패 했습니다. ${error}`)
    })
}

export function deleteApi(url, data, callback, key) {
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then((response) => response.json()).then((json) => {
        if (json.status === undefined) {
            if (callback) callback(json)
            //alert(`${key} - 삭제 되었습니다.`)
        } else {
            console.error(json);
            alert(`삭제 실패 했습니다. ${json.message}`)
        }
    }).catch((error) => {
        console.error(error);
        if (error.message === 'Failed to fetch') window.location = "/"
        else alert(`${key} - 삭제 실패 했습니다. ${error}`)
    })
}

export function genPathUrl(bucketName, key) {
    const baseUrl = `${window.location.protocol}//${window.location.host}`
    return `${baseUrl}/path/?key=${bucketName}/${encodeURIComponent(key)}`
}

export default App;
